import { useContext, useState } from 'react'

import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CashSummaryTable from 'components/Graphs/CashSummaryTable'
import ChargesTable from 'components/Graphs/ChargesTable'
import DepositsWithdrawalsTable from 'components/Graphs/DepositsWithdrawalsTable'
import DividendsTable from 'components/Graphs/DividendsTable'
import TransactionsTable from 'components/Graphs/TransactionsTable'
import AuthContext from 'context/authContext'
import useResponsive from 'hooks/useResponsive'
import { useTranslation } from 'react-i18next'
import Auxiliary from 'utils/Auxiliary/Auxiliary'
import CustomModeButtonGroup from 'utils/UI/Buttons/CustomModeButtonGroup'

const useStyles = makeStyles((theme) => ({
  graphSelectorsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    width: '100%',
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5)
    }
  }
}))

export default function Movements() {
  const { t } = useTranslation(['dashboard', 'common'])
  const { userDetails } = useContext(AuthContext)
  const isHijacked = !!window.localStorage.getItem('HIJACK_ADMIN_TOKENS')
  const canViewSummary = userDetails?.isStaff || isHijacked

  const defaultModeList = [
    ...(canViewSummary ? [{ key: 'summary', label: t('Resumen') }] : []),
    { key: 'transactions', label: t('Transacciones') },
    { key: 'depositsWithDrawls', label: t('Aportes y Rescates') },
    { key: 'dividends', label: t('Dividendos e Intereses') },
    { key: 'charges', label: t('Cargos') }
  ]
  const classes = useStyles()
  const { isMobile } = useResponsive()
  const [aggregate, setMode] = useState(
    canViewSummary ? 'summary' : 'transactions'
  )

  return (
    <Auxiliary>
      <div className={classes.graphSelectorsContainer}>
        <div className={classes.graphDataType}>
          <Grid container={isMobile}>
            <CustomModeButtonGroup
              modeList={defaultModeList}
              currentValue={aggregate}
              onClickHandler={setMode}
            />
          </Grid>
        </div>
      </div>
      {aggregate === 'summary' && canViewSummary ? <CashSummaryTable /> : null}
      {aggregate === 'transactions' ? <TransactionsTable /> : null}
      {aggregate === 'depositsWithDrawls' ? <DepositsWithdrawalsTable /> : null}
      {aggregate === 'dividends' ? <DividendsTable /> : null}
      {aggregate === 'charges' ? <ChargesTable /> : null}
    </Auxiliary>
  )
}
