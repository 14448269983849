import { useContext, useEffect, useState } from 'react'

import clsx from 'clsx'
import { format } from 'date-fns'
import ReactDOM from 'react-dom'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import {
  default as ExpandMore,
  default as ExpandMoreIcon
} from '@material-ui/icons/ExpandMore'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { useTranslation } from 'react-i18next'

import { StyledTableCell, StyledTableContainer } from 'assets/jss/Tables/Tables'
import HeaderContext from 'context/headerContext'
import Auxiliary from 'utils/Auxiliary/Auxiliary'
import decoder from 'utils/Decoder/decoder'
import { bigNumberFormatter } from 'utils/formatters/currencyFormatters'
import { percentageFormatter } from 'utils/formatters/percentageFormatters'
import DownloadButton from 'utils/UI/Buttons/DownloadButton'
import Loading from 'utils/UI/Loading'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import { treeReportRequest, treeTableRequest } from 'axios/requests/analysis'

const CollapseButton = withStyles(() => ({
  root: {
    padding: 0,
    minWidth: 0
  }
}))(Button)

const StyledTable = withStyles(() => ({
  root: {
    tableLayout: 'auto'
  }
}))(Table)

const useStyles = makeStyles((theme) => ({
  showTableRow: {
    height: '25px',
    maxHeight: '25px',
    minHeight: '25px'
  },
  hoverTableRow: {
    '&:hover td': {
      color: theme.palette.primary.main
    }
  },
  hideTableRow: {
    display: 'none'
  },
  shiftLeft: {
    paddingLeft: 24
  },
  assetClassName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 0
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  treeTableContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTableContainer-root': {
      minWidth: 400,
      '& th:first-child': {
        minWidth: 175,
        maxWidth: 175
      },
      '& td:first-child': {
        minWidth: 175,
        maxWidth: 175
      },
      '& .MuiTableCell-sizeSmall': {
        padding: '6px 0 6px 16px'
      },
      [theme.breakpoints.down('sm')]: {
        '& *': {
          fontSize: '0.8rem'
        }
      }
    }
  },
  downloadBtnDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10
  },
  assetCell: {
    display: 'flex',
    alignItems: 'center'
  },
  errorColor: {
    color: theme.palette.error.main
  }
}))

export default function TreeTable({ selectedPortfolio = null }) {
  const { t } = useTranslation(['dashboard', 'common', 'asset_classes'])
  const colNames = [
    t('Clase de Activo'),
    t('Valor'),
    t('Retorno'),
    t('Posición'),
    t('Objetivo')
  ]
  const classes = useStyles()
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currency, currentPortfolio } = headerState
  const [collapseTable, setCollapseTable] = useState({})
  const [tableData, setTableData] = useState(null)
  const [assetClass] = useState('ALL')
  const [loadingFile, setLoadingFile] = useState(false)
  const [loading, setLoading] = useState(false)
  const [portfolioToLoad, setPortfolioToLoad] = useState(currentPortfolio)

  useEffect(() => {
    if (currentPortfolio) {
      setPortfolioToLoad(currentPortfolio)
    } else {
      setPortfolioToLoad(selectedPortfolio)
    }
  }, [currentPortfolio, selectedPortfolio])

  if (collapseTable.parentTasks === undefined) {
    const newstate = { ...collapseTable, parentTasks: true }
    setCollapseTable(newstate)
  }

  useEffect(() => {
    if (!portfolioToLoad) return
    setLoading(true)
    let mounted = true
    const fetchData = async () => {
      try {
        const result = await treeTableRequest({
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          currency: currency,
          portfolio_id: portfolioToLoad.id,
          asset_class: assetClass
        })
        return result.data
      } catch (err) {
        console.log(err)
      }
    }

    if (mounted && portfolioToLoad && currency) {
      fetchData().then((data) => {
        if (mounted && data?.summaryAssetsData) {
          setTableData(data.summaryAssetsData)
          setLoading(false)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [currency, portfolioToLoad, startDate, endDate, assetClass])

  useEffect(() => {
    if (!tableData) return
    bindTooltips()
  }, [tableData])

  const bindTooltips = () => {
    setTimeout(() => {
      const assetsElements = document.getElementsByClassName('assetName')
      var fun = function () {
        if (this.offsetWidth < this.scrollWidth) {
          // eslint-disable-next-line react/no-deprecated
          ReactDOM.render(
            <Tooltip title={t(this.dataset.asset, { ns: 'asset_classes' })}>
              <span>{t(this.dataset.asset, { ns: 'asset_classes' })}</span>
            </Tooltip>,
            this
          )
        } else {
          // eslint-disable-next-line react/no-deprecated
          ReactDOM.render(t(this.dataset.asset, { ns: 'asset_classes' }), this)
        }
      }
      for (var i = 0; i < assetsElements.length; i++) {
        assetsElements[i].addEventListener('mouseover', fun, false)
      }
    }, 500)
  }

  const recursiveCollapse = (node, previousState, collapseAll = false) => {
    let newState = {
      ...collapseTable,
      ...previousState,
      [node.asset_class.id]: collapseAll
    }
    if ((node.children.length > 0) & (node.weight > 0)) {
      for (let index = 0; index < node.children.length; index++) {
        const child = node.children[index]
        newState = recursiveCollapse(child, newState, collapseAll)
      }
    }
    return newState
  }

  const handleCollapse = (node) => {
    let newState
    if (
      !collapseTable[node.asset_class.id] ||
      collapseTable[node.asset_class.id] === null
    ) {
      newState = { ...collapseTable, [node.asset_class.id]: true }
    } else {
      newState = recursiveCollapse(node, {})
    }
    setCollapseTable(newState)
    bindTooltips()
  }

  const handleCollapseAll = (type) => {
    if (!tableData) return
    if (type === 'close') {
      setCollapseTable({})
      return
    }
    let newState = {}
    tableData.children.forEach((rowData) => {
      newState[rowData.asset_class.id] = false
      newState = recursiveCollapse(rowData, newState, true)
    })
    setCollapseTable(newState)
    bindTooltips()
  }

  const TableTreeRow = (data, collapseId) => {
    let nestedData = data.children
    const currentLevel = data.asset_class ? data.asset_class.level + 1 : 0

    // Reorder to show assets before asset classes
    if (data.assets && data.assets.length > 0) {
      nestedData = [...data.assets, ...nestedData]
    }

    return nestedData.map((rowData) => {
      let assetName =
        rowData.asset_class?.name ?? rowData.asset_name ?? rowData.asset.name

      const translatedAssetName = t(assetName, { ns: 'asset_classes' })

      const canExpand =
        (rowData.children && rowData.children.length > 0) || rowData.assets
      return rowData.weight > 0 || rowData.target_weight > 0 ? (
        <Auxiliary
          key={rowData.asset_class ? rowData.asset_class.id : rowData.asset.id}
        >
          <TableRow
            hover
            classes={{
              hover: classes.hoverTableRow
            }}
            className={clsx({
              [classes.showTableRow]: collapseTable[collapseId],
              [classes.hideTableRow]: !collapseTable[collapseId]
            })}
          >
            <StyledTableCell style={{ paddingLeft: 10 * currentLevel }}>
              <Collapse
                in={collapseTable[collapseId]}
                timeout="auto"
                unmountOnExit
                className={classes.assetNameCol}
              >
                <div className={classes.assetCell}>
                  {canExpand ? (
                    <CollapseButton onClick={() => handleCollapse(rowData)}>
                      {collapseTable[rowData.asset_class.id] ? (
                        <ExpandMore />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )}
                    </CollapseButton>
                  ) : null}
                  <p
                    onClick={canExpand ? () => handleCollapse(rowData) : null}
                    data-asset={translatedAssetName}
                    className={clsx('assetName', classes.assetClassName, {
                      [classes.shiftLeft]: !canExpand,
                      [classes.cursorPointer]: canExpand
                    })}
                  >
                    {translatedAssetName}
                  </p>
                </div>
              </Collapse>
            </StyledTableCell>
            <StyledTableCell
              align="right"
              className={clsx({ [classes.errorColor]: rowData.value < 0 })}
            >
              <Collapse
                in={collapseTable[collapseId]}
                timeout="auto"
                unmountOnExit
              >
                {bigNumberFormatter(rowData.value)}
              </Collapse>
            </StyledTableCell>
            <StyledTableCell
              align="right"
              className={clsx({
                [classes.errorColor]: rowData.total_return < 0
              })}
            >
              <Collapse
                in={collapseTable[collapseId]}
                timeout="auto"
                unmountOnExit
              >
                {rowData.total_return &&
                  percentageFormatter(rowData.total_return * 100)}
              </Collapse>
            </StyledTableCell>
            <StyledTableCell
              align="right"
              className={clsx({ [classes.errorColor]: rowData.weight < 0 })}
            >
              <Collapse
                in={collapseTable[collapseId]}
                timeout="auto"
                unmountOnExit
              >
                {rowData.weight && percentageFormatter(rowData.weight * 100)}
              </Collapse>
            </StyledTableCell>
            <StyledTableCell
              align="right"
              className={clsx({
                [classes.errorColor]: rowData.target_weight < 0
              })}
            >
              <Collapse
                in={collapseTable[collapseId]}
                timeout="auto"
                unmountOnExit
              >
                {rowData.target_weight &&
                  percentageFormatter(rowData.target_weight * 100)}
              </Collapse>
            </StyledTableCell>
          </TableRow>
          {!rowData.asset
            ? TableTreeRow(rowData, rowData.asset_class.id)
            : null}
        </Auxiliary>
      ) : null
    })
  }

  const handleDownload = async () => {
    setLoadingFile(true)
    treeReportRequest({
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      currency: currency,
      portfolio_id: portfolioToLoad.id,
      asset_class: assetClass
    })
      .then((response) => {
        const blob = decoder(response.data)
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          'Abaqus - ' + portfolioToLoad.name + '.xlsx'
        ) // or any other extension
        document.body.appendChild(link)
        link.click()
        setLoadingFile(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <CardWrapper
      title={t('Detalle Posiciones')}
      titleFeedback={t('Detalle Posiciones')}
      action={
        <div className={classes.downloadBtnDiv}>
          <IconButton onClick={() => handleCollapseAll('open')}>
            <ExpandMoreIcon />
          </IconButton>
          <IconButton onClick={() => handleCollapseAll('close')}>
            <KeyboardArrowUpIcon />
          </IconButton>
          {loadingFile ? (
            <CircularProgress />
          ) : (
            <DownloadButton onlyIcon onClickHandler={handleDownload} />
          )}
        </div>
      }
    >
      {loading ? (
        <Loading />
      ) : !portfolioToLoad ? (
        <Grid container justifyContent="center" style={{ paddingTop: 45 }}>
          <Typography variant="h6">
            {t('Selecciona un portafolio para ver el detalle de posiciones')}
          </Typography>
        </Grid>
      ) : (
        <div className={classes.treeTableContainer}>
          <StyledTableContainer>
            <StyledTable
              size="small"
              style={{
                overflowX: 'auto',
                maxWidth: '100%'
              }}
              scrollable
            >
              <TableHead>
                <TableRow>
                  {colNames.map((colName) => {
                    return (
                      <StyledTableCell
                        key={colName}
                        size="small"
                        align={
                          colName === t('Clase de Activo') ? 'left' : 'right'
                        }
                      >
                        {colName}
                      </StyledTableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData ? TableTreeRow(tableData, 'parentTasks') : null}
              </TableBody>
            </StyledTable>
          </StyledTableContainer>
        </div>
      )}
    </CardWrapper>
  )
}
