import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import React from 'react'
import baseStyles from 'utils/UI/BaseStyles'
import { percentageFormatter2 } from 'utils/formatters/percentageFormatters2'

const ReturnsTableComponent = ({ returnsData }) => {
  const classes = baseStyles()

  const currentYear = new Date().getFullYear()
  const lastYear = currentYear - 1
  const twoYearsAgo = currentYear - 2
  const threeYearsAgo = currentYear - 3

  if (!returnsData || returnsData.length === 0) {
    return <div>No hay datos para mostrar</div>
  }

  const order = [
    'Custom',
    'CAGR',
    'Vol',
    '1M',
    '3M',
    'YTD',
    `${lastYear}`,
    `${twoYearsAgo}`,
    `${threeYearsAgo}`,
    '1Y',
    '3Y',
    '5Y'
  ]

  const getColorClass = (value) => {
    if (!value || value === '-') return ''
    const numericValue = parseFloat(value.replace('%', ''))

    return numericValue >= 0 ? classes.successColor : classes.errorColor
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>Portafolio</TableCell>
            {order.map((indicator) => (
              <TableCell
                key={indicator}
                style={{ fontWeight: 'bold' }}
                align="center"
              >
                {indicator}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {returnsData.map((portfolio) => {
            const indicators = {
              CAGR: portfolio.cagr
                ? percentageFormatter2(portfolio.cagr * 100)
                : '-',
              Vol: portfolio.vol
                ? percentageFormatter2(portfolio.vol * 100)
                : '-',
              ...Object.keys(portfolio.returns).reduce((acc, key) => {
                acc[key] = portfolio.returns[key]
                  ? percentageFormatter2((portfolio.returns[key] - 1) * 100)
                  : '-'
                return acc
              }, {})
            }

            return (
              <TableRow key={portfolio.name}>
                <TableCell style={{ fontWeight: 'bold' }}>
                  {portfolio.name}
                </TableCell>
                {order.map((indicator) => (
                  <TableCell
                    key={indicator}
                    align="center"
                    className={getColorClass(indicators[indicator])}
                  >
                    {indicators[indicator]}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const ReturnsTable = React.memo(ReturnsTableComponent)

export default ReturnsTable
