import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next' // Import the useTranslation hook

import clsx from 'clsx'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { Checkbox, Hidden, ListItemText, MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import CustomModeButtonGroup from 'utils/UI/Buttons/CustomModeButtonGroup'
import Loading from 'utils/UI/Loading'

import useResponsive from 'hooks/useResponsive'
import baseStyles from 'utils/UI/BaseStyles'
import { COLORS_MULTI_SELECT } from 'utils/UI/Theme'
import { bigNumberFormatter } from 'utils/formatters/currencyFormatters'
import { numberFormatter } from 'utils/formatters/numberFormatters'
import { percentageFormatter } from 'utils/formatters/percentageFormatters'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginLeft: 0
    }
  },
  gridContainer: {
    marginBottom: theme.spacing(2)
  },
  returnCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    fontSize: 22,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    }
  },
  comparatorCell: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  comparatorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  returnIconContainer: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    height: '100%'
  }
}))

export default function BaseGraph({
  balanceData,
  seriesData,
  portfolio,
  portfolios,
  portfoliosDict,
  comparators,
  setComparators,
  mode,
  setMode,
  currency
}) {
  const { t } = useTranslation(['dashboard']) // Get the translation function
  const baseClasses = baseStyles()
  const classes = useStyles()
  const theme = useTheme()
  const { isMobile } = useResponsive()

  const [loading, setLoading] = useState(false)
  const [portfolioReturn, setPortfolioReturn] = useState(0)

  const [chartOptions, setChartOptions] = useState({
    credits: false,
    plotOptions: {
      line: {
        marker: {
          enabled: false
        },
        events: {
          legendItemClick: function () {
            return false
          }
        }
      }
    },
    chart: {
      zoomType: 'x',
      style: {
        fontFamily: 'InconsolataRegular'
      }
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'datetime',
      crosshair: true,
      labels: {
        style: {
          color: theme.palette.text.primary
        }
      }
    },
    yAxis: {
      title: {
        text: null
      },
      plotLines: [
        {
          value: 0,
          width: 1,
          color: theme.palette.text.primary
        }
      ],
      labels: {
        style: {
          color: theme.palette.text.primary
        }
      }
    },
    tooltip: {
      shared: true,
      valueDecimals: 2,
      formatter: function () {
        let points = ''
        this.points
          .sort((a, b) => {
            return b.point.y - a.point.y
          })
          .forEach((point) => {
            // eslint-disable-next-line prettier/prettier
            points += `<span style="color: ${point.series.color}">●</span> ${
              point.series.name
            } : ${point.y.toFixed(2)}<br>`
          })
        return (
          '<b style="font-size: 11px  ">' +
          Highcharts.dateFormat('%d/%m/%Y', this.x) +
          '</b>' +
          '<br>' +
          '<div style="margin-top: 20px">' +
          points +
          '</div>'
        )
      }
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      itemStyle: {
        fontFamily: 'InconsolataRegular',
        fontSize: 14,
        fontWeight: 'normal',
        color: theme.palette.text.primary
      }
    },
    lang: {
      noData: ''
    },
    series: [
      {
        name: '',
        color: theme.palette.primary.main,
        data: [],
        turboThreshold: 0
      }
    ]
  })

  const defaultModeList = [
    { key: 'valor-cuota', label: t('Valor Cuota') },
    { key: 'irr', label: t('TIR') },
    { key: 'total-account-value', label: t('Patrimonio') }
  ]

  useEffect(() => {
    let mounted = true
    if (mounted && portfolio && currency) {
      window.addEventListener('transitionend', () => {
        Highcharts.charts.forEach((chart) => {
          if (chart) {
            chart.reflow()
          }
        })
      })
      setLoading(true)
      if (mounted && seriesData?.portfolioData) {
        // format data into HigCharts format
        const portfolioData = []
        const comparatorsDict = {}
        for (let index = 0; index < seriesData.portfolioData.length; index++) {
          const element = seriesData.portfolioData[index]
          portfolioData.push([Date.parse(element[0]), element[1]])
        }
        if (seriesData?.comparatorData) {
          seriesData.comparatorData.forEach(({ comparator, data }) => {
            const comparatorData = []
            for (let index = 0; index < data.length; index++) {
              const element = data[index]
              comparatorData.push([Date.parse(element[0]), element[1]])
            }
            comparatorsDict[comparator] = comparatorData
          })
        }
        // calculate portfolio return
        const portReturn =
          parseFloat(
            seriesData.portfolioData[seriesData.portfolioData.length - 1][1]
          ) - 100
        setPortfolioReturn(portReturn)
        // only show comparators if mode is valor-cuota and have comparators
        if (comparators.length && mode === 'valor-cuota') {
          let chartOptionsName = 'Portafolio ' + portfolio.name
          const updatedChartOptions = {
            ...chartOptions,
            series: [
              {
                name: chartOptionsName,
                data: portfolioData,
                color: theme.palette.primary.main,
                turboThreshold: 0
              },
              ...Object.keys(comparatorsDict).map((comparator, i) => ({
                name:
                  portfoliosDict[comparator]?.name || comparator.toUpperCase(),
                data: comparatorsDict[comparator],
                color: COLORS_MULTI_SELECT[i]
              }))
            ],
            tooltip: {
              shared: true,
              valueDecimals: 2,
              formatter: getTooltipFormatter()
            }
          }
          setChartOptions(updatedChartOptions)
        } else {
          let chartOptionsName = 'Portfolio ' + portfolio.name
          const updatedChartOptions = {
            ...chartOptions,
            series: [
              {
                name: chartOptionsName,
                data: portfolioData,
                color: theme.palette.primary.main,
                turboThreshold: 0
              }
            ],
            tooltip: {
              shared: true,
              valueDecimals: 2,
              formatter: getTooltipFormatter()
            }
          }
          setChartOptions(updatedChartOptions)
        }
      }
      Highcharts.charts.forEach((chart) => {
        if (chart) {
          chart.hideLoading()
        }
      })
      setLoading(false)
    }

    return () => {
      mounted = false
      window.removeEventListener('transitionend', () => {
        Highcharts.charts.forEach((chart) => {
          if (chart) {
            chart.reflow()
          }
        })
      })
    }
  }, [balanceData, seriesData, mode, currency, comparators, portfolio])

  const returnValue = useMemo(() => {
    let value
    let valueFormatted
    if (mode === 'total-account-value') {
      value = balanceData.total_return
      valueFormatted = bigNumberFormatter(balanceData.total_return)
    } else {
      value = portfolioReturn
      valueFormatted = percentageFormatter(portfolioReturn)
    }
    return {
      value,
      valueFormatted
    }
  }, [mode, balanceData, portfolioReturn])

  const handleComparatorSelection = (event) => {
    setComparators(event.target.value)
  }

  function getTooltipFormatter() {
    return function () {
      let points = ''
      this.points
        .sort((a, b) => {
          return b.point.y - a.point.y
        })
        .forEach((point) => {
          points += `<span style='color: ${point.series.color}'>●</span>
          ${point.series.name} : 
          ${
            mode === 'total-account-value'
              ? numberFormatter(point.y.toFixed(2))
              : point.y.toFixed(2)
          }<br>`
        })
      return (
        '<b style="font-size: 11px  ">' +
        Highcharts.dateFormat('%d/%m/%Y', this.x) +
        '</b>' +
        '<br>' +
        '<div style="margin-top: 20px">' +
        points +
        '</div>'
      )
    }
  }

  const renderModeButtonGroup = (
    <CustomModeButtonGroup
      modeList={defaultModeList}
      currentValue={mode}
      onClickHandler={setMode}
    />
  )

  const renderComparatorSelector = (
    <FormControl
      variant="outlined"
      size="small"
      className={classes.formControl}
      disabled={mode !== 'valor-cuota'}
      fullWidth
    >
      <InputLabel id="demo-multiple-checkbox-label">
        {comparators.length ? t('Comparadores') : t('Comparador')}
      </InputLabel>
      <Select
        multiple
        value={comparators}
        onChange={handleComparatorSelection}
        label={t('Comparadores')}
        renderValue={(selected) => selected.join(', ')}
        inputProps={{
          name: 'age',
          id: 'outlined-age-native-simple'
        }}
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
      >
        <MenuItem value="ipsa">
          <Checkbox checked={comparators.indexOf('ipsa') > -1} />
          <ListItemText primary="IPSA" />
        </MenuItem>
        <MenuItem value="sp500">
          <Checkbox checked={comparators.indexOf('sp500') > -1} />
          <ListItemText primary="S&P500" />
        </MenuItem>
        <MenuItem value="uf">
          <Checkbox checked={comparators.indexOf('uf') > -1} />
          <ListItemText primary="UF" />
        </MenuItem>
        <MenuItem value="usd">
          <Checkbox checked={comparators.indexOf('usd') > -1} />
          <ListItemText primary="USD" />
        </MenuItem>
        {portfolios
          ? portfolios.map((portf) => (
              <MenuItem key={portf.id} value={portf.code}>
                <Checkbox checked={comparators.indexOf(portf.code) > -1} />
                <ListItemText primary={portf.name} />
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  )
  return (
    <>
      {portfolioReturn !== null && portfolioReturn !== undefined ? (
        <>
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.gridContainer}
          >
            {balanceData && (
              <Grid item xs={isMobile ? 4 : 3} className={classes.returnCell}>
                {!loading ? (
                  <>
                    <span
                      className={clsx(classes.returnPercentage, {
                        [baseClasses.errorColor]: returnValue.value < 0,
                        [baseClasses.successColor]: returnValue.value > 0
                      })}
                    >
                      {returnValue.valueFormatted}
                    </span>
                    <div className={classes.returnIconContainer}>
                      {returnValue.value > 0 ? (
                        <ArrowUpwardIcon
                          className={clsx({
                            [baseClasses.errorColor]: returnValue.value < 0,
                            [baseClasses.successColor]: returnValue.value > 0
                          })}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          className={clsx({
                            [baseClasses.errorColor]: returnValue.value < 0,
                            [baseClasses.successColor]: returnValue.value > 0
                          })}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  '-'
                )}
              </Grid>
            )}
            <Hidden smDown>
              <Grid item xs={6} className={classes.modeCell}>
                {renderModeButtonGroup}
              </Grid>
            </Hidden>
            <Grid
              item
              xs={isMobile ? 8 : 3}
              className={classes.comparatorContainer}
            >
              {renderComparatorSelector}
            </Grid>
          </Grid>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </>
      ) : (
        <Loading />
      )}
    </>
  )
}
