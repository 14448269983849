import { getRequest } from 'axios/request'

export function TransactionsRequest(params) {
  const url = '/portfolios/data/transactions/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function DepositsWithdrawlsRequest(params) {
  const url = '/portfolios/data/deposits-withdrawals/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function DividendsRequest(params) {
  const url = '/portfolios/data/dividends/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function DownloadDividendsRequest(params) {
  const url = '/portfolios/data/dividends/download/'
  return getRequest(
    url,
    {
      ...params
    },
    {},
    'blob'
  )
}

export function DownloadTransactionsRequest(params) {
  const url = '/portfolios/data/transactions/download/'
  return getRequest(
    url,
    {
      ...params
    },
    {},
    'blob'
  )
}

export function DownloadDepositsWithdrawlsRequest(params) {
  const url = '/portfolios/data/deposits-withdrawals/download/'
  return getRequest(
    url,
    {
      ...params
    },
    {},
    'blob'
  )
}

export function ChargesRequest(params) {
  const url = '/portfolios/data/charges/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function DownloadChargesRequest(params) {
  const url = '/portfolios/data/charges/download/'
  return getRequest(
    url,
    {
      ...params
    },
    {},
    'blob'
  )
}

export function CashSummaryRequest(params) {
  const url = '/portfolios/data/cash-summary/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}
