import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import HeaderContext from 'context/headerContext'
import { format } from 'date-fns'
import useDebounce from 'hooks/useDebounce'
import moment from 'moment'
import Search from 'utils/UI/Inputs/Search'
import Loading from 'utils/UI/Loading'
import DefaultTable from 'utils/UI/Tables/DefaultTable'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'
import { smallNumberFormatter } from 'utils/formatters/currencyFormatters'

import {
  ChargesRequest,
  DownloadChargesRequest
} from 'axios/requests/movements'
import { errorNotification } from 'utils/UI/Notifications/Notifications'

import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import GetAppIcon from '@material-ui/icons/GetApp'

export default function ChargesTable() {
  const { t } = useTranslation(['dashboard', 'common'])
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currentPortfolio } = headerState
  const [rowsData, setRowsData] = useState(null)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let mounted = true
    const fetchData = async () => {
      try {
        setLoading(true)
        const result = await ChargesRequest({
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          portfolio_id: currentPortfolio.id,
          search: debouncedSearch
        })
        return result.data
      } catch (err) {
        errorNotification('generalError')
        setLoading(false)
      }
    }

    if (mounted && currentPortfolio) {
      fetchData().then((data) => {
        if (mounted && data) {
          const newData = data.map((x) => {
            return { ...x, date: moment(x.date).format('DD-MM-YYYY') }
          })
          setRowsData(newData)
          setLoading(false)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [currentPortfolio, startDate, endDate, debouncedSearch])

  const colsData = [
    {
      id: 'date',
      label: t('Fecha'),
      bold: true,
      align: 'left'
    },
    {
      id: 'account_name',
      label: t('Cuenta'),
      minWidth: 400,
      ellipsis: true,
      tooltipValue: (row) => row.account_name
    },
    {
      id: 'currency',
      minWidth: 80,
      label: t('Moneda'),
      align: 'right'
    },
    {
      id: 'amount_clp',
      label: t('Monto CLP'),
      align: 'right',
      format: (value) => smallNumberFormatter(value)
    },
    {
      id: 'amount_usd',
      label: t('Monto USD'),
      align: 'right',
      format: (value) => smallNumberFormatter(value)
    }
  ]

  const downloadHandler = async () => {
    setLoadingDownload(true)
    try {
      const response = await DownloadChargesRequest({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        portfolio_id: currentPortfolio.id,
        search: debouncedSearch
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Abaqus - Cargos.xlsx')
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingDownload(false)
  }

  return (
    <CardWrapper title={t('Cargos')}>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Tooltip title={rowsData?.length ? t('Descargar') : ''}>
            {loadingDownload ? (
              <CircularProgress />
            ) : (
              <IconButton
                disabled={!rowsData?.length}
                onClick={downloadHandler}
                color="primary"
              >
                <GetAppIcon />
              </IconButton>
            )}
          </Tooltip>
        </Grid>
        <Grid item>
          <Search onChange={setSearch} value={search} />
        </Grid>
        <Grid item xs={12}>
          {loading || !rowsData ? (
            <Loading />
          ) : (
            <DefaultTable
              colsData={colsData}
              rowsData={rowsData}
              maxHeight={false}
            />
          )}
        </Grid>
      </Grid>
    </CardWrapper>
  )
}
